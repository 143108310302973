<template>
<b-container>
  <div class="my-5">
    <div class="row pr-4 pl-4 mt-5">
      <h4>
        {{ companyId ? 'แก้ไขข้อมูลบริษัท' : 'ลงทะเบียน ผู้ประกอบการ'}}
      </h4>
      <divider />
      <b-row class="w-100 pb-5 mb-5">
        <b-colxx lg="12">
          <b-form @submit.prevent="onSubmit">
            <b-row>
              <b-colxx lg="4">
                <img
                  class="img-preview"
                  v-if="payload.logo"
                  :src="payload.logo"
                />
                <b-form-group >
                  <label>โลโก้บริษัท</label>
                  <b-input-group>
                    <b-form-input type="text" v-model="payload.logo" readonly />
                    <b-input-group-append>
                      <b-button
                        variant="primary"
                        @click="showUploadFile('logo')"
                      >
                        Upload
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-colxx>
              <b-colxx lg="4">
                <img
                  class="img-preview"
                  v-if="payload.profile"
                  :src="payload.profile"
                />
                <b-form-group>
                  <label> แบนเนอร์ขนาดเล็ก</label>
                  <b-input-group>
                    <b-form-input
                      type="text"
                      v-model="payload.profile"
                      readonly
                    />
                    <b-input-group-append>
                      <b-button
                        variant="primary"
                        @click="showUploadFile('profile')"
                      >
                        Upload
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-colxx>
              <b-colxx lg="4">
                <img
                  class="img-preview"
                  v-if="payload.banners"
                  :src="payload.banners"
                />
                <b-form-group>
                  <label> แบนเนอร์ขนาดใหญ่</label>
                  <b-input-group>
                    <b-form-input
                      type="text"
                      v-model="payload.banners"
                      readonly
                    />
                    <b-input-group-append>
                      <b-button
                        variant="primary"
                        @click="showUploadFile('banners')"
                      >
                        Upload
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-colxx>
            </b-row>
            <b-row>
              <b-colxx lg="4">
                <b-form-group> 
                  <label> ชื่อบริษัท (ภาษาไทย) <span class="req">*</span></label>
                  <b-form-input type="text" v-model="payload.name.th" required/>
                </b-form-group>
              </b-colxx>
              <b-colxx lg="4">
                <b-form-group>
                  <label> ชื่อบริษัท (ภาษาอังกฤษ) <span class="req">*</span></label>
                  <b-form-input type="text" v-model="payload.name.en" required/>
                </b-form-group>
              </b-colxx>
            </b-row>
            <b-row>
              <b-colxx lg="4">
                <b-form-group>
                  <label> คำอธิบายบริษัท (ภาษาไทย) <span class="req">*</span></label>
                  <b-textarea
                    required
                    v-model="payload.descriptions.th"
                    :rows="4"
                    :max-rows="4"
                  />
                </b-form-group>
              </b-colxx>
              <b-colxx lg="4">
                <b-form-group>
                  <label> คำอธิบายบริษัท (ภาษาอังกฤษ) <span class="req">*</span></label>
                  <b-textarea
                    required
                    v-model="payload.descriptions.en"
                    :rows="4"
                    :max-rows="4"
                  />
                </b-form-group>
              </b-colxx>
            </b-row>
              <b-row>
              <b-colxx lg="4">
                <b-form-group>
                  <label> ที่อยู่บริษัท (ภาษาไทย) <span class="req">*</span></label>
                  <b-textarea
                    required
                    v-model="address.address.th"
                    :rows="4"
                    :max-rows="4"
                  />
                </b-form-group>
              </b-colxx>
              <b-colxx lg="4">
                <b-form-group>
                  <label> ที่อยู่บริษัท (ภาษาอังกฤษ) <span class="req">*</span></label>
                  <b-textarea
                    required
                    v-model="address.address.en"
                    :rows="4"
                    :max-rows="4"
                  />
                </b-form-group>
              </b-colxx>
            </b-row>
            <b-row>
                <b-colxx lg="4">
                <b-form-group>
                  <label> จังหวัด <span class="req">*</span></label>
                  <v-select
                    autocomplete="off"
                    required
                    v-model="address.province.th"
                    :options="provincesOption"
                    :reduce="option => option.value"
                  />
                </b-form-group>
              </b-colxx>
              <b-colxx lg="4">
                <b-form-group>
                  <label> เขต/อำเภอ <span class="req">*</span></label>
                  <v-select
                    autocomplete="off"
                    required
                    v-model="address.district.th"
                    :options="districtsOption"
                    :reduce="option => option.value"
                  />
                </b-form-group>
              </b-colxx>
            </b-row>
            <b-row>
              <b-colxx lg="4">
                <b-form-group>
                  <label> แขวง/ตำบล <span class="req">*</span></label>
                  <v-select
                    autocomplete="off"
                    required
                    v-model="address.subdistrict.th"
                    :options="subdistrictsOption"
                    :reduce="option => option.value"
                  />
                </b-form-group>
              </b-colxx>
              <b-colxx lg="4">
                <b-form-group>
                  <label> รหัสไปรษณีย์ <span class="req">*</span></label>
                  <b-form-input type="text" v-model="address.zipcode" required/>
                </b-form-group>
              </b-colxx>
              <b-colxx lg="4">
                <b-form-group>
                  <label> ประเภทธุรกิจ <span class="req">*</span></label>
                  <v-select
                    required
                    v-model="payload.businessCategory"
                    :options="categoriesOption"
                    :reduce="option => option.value"
                  />
                </b-form-group>
              </b-colxx>
            </b-row>
            <b-row>
              <b-colxx lg="4">
                <b-form-group>
                  <label> เบอร์โทรศัพท์ <span class="req">*</span></label>
                  <b-form-input type="text" v-model="payload.tel" required/>
                </b-form-group>
              </b-colxx>
              <b-colxx lg="4">
                <b-form-group>
                  <label> E-mail บริษัท<span class="req">*</span></label>
                  <b-form-input
                    required
                    type="email"
                    v-model="payload.email"
                  />
                </b-form-group>
              </b-colxx>
              <b-colxx lg="4">
                <b-form-group>
                  <label> เว็บไซต์บริษัท<span class="req">*</span></label>
                  <b-form-input type="text" v-model="payload.website" required/>
                </b-form-group>
              </b-colxx>
            </b-row>
            <h4 v-if="!companyId">ผู้ใช้งานหลัก</h4>
            <b-row v-if="!companyId">
              <b-colxx lg="4">
                <label> E-mail สำหรับเข้าใช้งาน<span class="req">*</span></label>
                <b-form-input type="text" v-model="payload.user.email" />
              </b-colxx>
              <b-colxx lg="4">
                <label> Password สำหรับเข้าใช้งาน<span class="req">*</span></label>
                <b-form-input type="text" v-model="payload.user.password" />
              </b-colxx>
            </b-row>
            <b-row>
              <b-colxx lg="6"> </b-colxx>
              <b-colxx lg="6"> </b-colxx>
            </b-row>
            <b-button type="submit" size="md" variant="primary" class="mt-4 mr-3">
              บันทึก
            </b-button>
            <b-button
              size="md"
              type="button"
              variant="danger"
              class="mt-4"
              @click="initPayload"
            >
              ล้าง
            </b-button>
          </b-form>
        </b-colxx>
      </b-row>
      <my-upload
        field="file"
        @crop-upload-success="cropUploadSuccess"
        @crop-upload-fail="cropUploadFail"
        v-if="show"
        v-model="show"
        :width="cropScale.width"
        :height="cropScale.height"
        :noCircle="true"
        :noRotate="false"
        langType="en"
        :url="apiBaseUpload"
        img-format="png"
      ></my-upload>
    </div>
  </div>
</b-container>
</template>

<script>
import vSelect from "vue-select";
import { apiUrl } from "@/configs";
// import { getProvince, getDistrict, getSubDistrict } from "@/services/common.service";
import { createCompany, getBusinessCategory, getCompanyById, updateCompanyProfile } from "@/services/company.service"
import { getProvinces, getDistricts, getSubDistricts, multiLingualAddress } from "@/services/address.service"
import { companySchema } from "@/schemas/company.schema";
import { addressSchema } from "@/schemas/address.schema";
import Divider from "@/components/Divider";
import myUpload from "vue-image-crop-upload";
import "vue-select/dist/vue-select.css";
export default {
  components: {
    Divider,
    "v-select": vSelect,
    "my-upload": myUpload
  },
  data() {
    return {
      companyId: null,
      apiBaseUpload: apiUrl + "/upload",
      provinces: [],
      districts: [],
      subdistricts: [],
      categories: [],
      payload: null,
      company: null,
      show: false,
      uploadTarget: null,
      address: {
        address: {
          th: "",
          en: ""
        },
        subdistrict: {
          th: "",
          en: ""
        },
        district: {
          th: "",
          en: ""
        },
        province: {
          th: "",
          en: ""
        },
        zipcode: "",
      },
    };
  },
  watch: {
    'address.province.th'() {
      this.loadDistrictData()
    },
    'address.district.th'() {
      this.loadSubDistrictData()
    },
    'address.subdistrict.th'() {
      let subdistrict = this.address.subdistrict.th || ''
      let findZipcode = this.subdistricts.find((item) => item.subDistrict == subdistrict);
      this.address.zipcode = findZipcode && findZipcode.zipcode || this.address.zipcode;
    },
    // 'address.province.th'() {
    //   this.address.district = { th: "", en: ""};
    //   this.address.subdistrict = { th: "", en: ""};
    //   this.address.zipcode = "";
    //   this.loadDistrictData()
    // },
    // 'address.district.th'() {
    //   this.address.subdistrict = { th: "", en: ""};
    //   this.address.zipcode = "";
    //   this.loadSubDistrictData()
    // },
    // 'address.subdistrict.th'() {
    //   let subdistrict = this.address.subdistrict.th || ''
    //   let findZipcode = this.subdistricts.find((item) => item.subDistrict == subdistrict);
    //   this.address.zipcode = findZipcode && findZipcode.zipcode;
    // },
    'payload.name.en'() {
      if(!this.companyId) {
        this.payload.user.fullname = `Mainuser ${this.payload.name.en}`;
      }
    },
    'payload.tel'() {
      if(!this.companyId) {
        this.payload.user.phone = this.payload.tel;
      }
    }
  },
  computed: {
    provincesOption() {
      return this.provinces.map(value => {
        return { label: value, value };
      })
    },
    districtsOption() {
      return this.districts.map(value => {
        return { label: value, value };
      })
    },
    subdistrictsOption() {
      return this.subdistricts.map(value => {
        return { label: value.subDistrict, value: value.subDistrict };
      })
    },
    categoriesOption() {
      return this.categories.map(cat => {
        return { label: cat.th, value: cat.th };
      });
    },
    cropScale() {
      let height = 720;
      let width = 720;
      if(this.uploadTarget === 'banners'){
        height = 517;
        width = 1130;
      }
      return { height, width };
    }
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.initPayload();
      const user = localStorage.getItem("user_info") && JSON.parse(localStorage.getItem("user_info"))
      if(user && user.company) {
        this.companyId = user.company._id
        if(window.location.pathname == '/createcompany') {
          window.location.href = '/companyprofile';
        }
      }
      if (!this.companyId && user) {
        window.location.href = '/';
      }
      await this.loadProvinceData();
      await this.loadBusinessCategoryData()
      if (this.companyId) {
        const { data } = await getCompanyById();
        this.payload = companySchema.parse(data);
        this.address = addressSchema.parse(data.address);
      }
    },
    initAddress() {
      this.address = {
        address: {
          th: "",
          en: ""
        },
        subdistrict: {
          th: "",
          en: ""
        },
        district: {
          th: "",
          en: ""
        },
        province: {
          th: "",
          en: ""
        },
        zipcode: "",
      }
    },
    async loadProvinceData() {
      this.provinces = await getProvinces();
    },
    async loadDistrictData() {
      if (this.address.province) {
        this.districts = await getDistricts(this.address.province.th || '');
      }
    },
    async loadSubDistrictData() {
      if (this.address.province || this.address.district) {
        this.subdistricts = await getSubDistricts(this.address.province.th || '', this.address.district.th || '');
      }
    },
    async loadBusinessCategoryData() {
      const { data } = await getBusinessCategory();
      this.categories = data;
    },
    async initPayload() {
      this.initAddress()
      this.payload = {
        logo: "",
        profile: "",
        banners: "",
        tel: "",
        website: "",
        email: "",
        name: {
          th: "",
          en: ""
        },
        descriptions: {
          th: "",
          en: ""
        },
        user: {
          fullname: "",
          email: "",
          phone: "",
          password: ""
        },
        businessCategory: ""
      };      
    },
    async onSubmit() {
      if (this.companyId) {
        await this.updateCompany()
      }
      else {
        this.createCompany();
      }
    },
    async updateCompany() {
      this.payload.address = await multiLingualAddress(this.address)
      const { error, message, validation } = await updateCompanyProfile(this.companyId, this.payload);
      if (error || validation) {
        Object.keys(validation).map((key) => {
          this.$toast.error(validation[key])
        })
        if(message) {
          this.$toast.error(message);
        }
        return;
      }
      this.$toast.success('Company profile has been updated')
      window.location.href = '/companyprofile';
    },
    async createCompany() {
      this.payload.address = await multiLingualAddress(this.address)
      const { error, message, validation } = await createCompany(this.payload);
      if (error || validation) {
        Object.keys(validation).map((key) => {
          this.$toast.error(validation[key])
        })
        if(!validation) {
          this.$toast.error(message);
        }
        return;
      }
      this.$toast.success('Registration is successful')
       window.location.href = '/';
    },
    showUploadFile(uploadTarget) {
      this.uploadTarget = uploadTarget;
      this.show = true;
    },
    cropUploadSuccess(jsonData) {
      if (!this.uploadTarget) return;
      this.payload[this.uploadTarget] = jsonData.location;
      this.show = false;
    },
    cropUploadFail() {
      this.$toast.error("อัปโหลดล้มเหลว");
      this.show = false;
    }
  }
  
}
</script>

<style lang="scss" scoped>
.img-preview {
  max-height: 100px;
}
.input-group > .input-group-append {
  margin-left: -4px !important;
  .btn {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }
}
label {
  color: $primary-hard-color !important;
  font-size: 15px;
}
input.form-control, textarea.form-control, span.vs__selected, b-form-input {
  color: #000000;
  font-size: 15px !important;
}
.btn {
  width: 6rem;
}
</style>
