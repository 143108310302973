import schema from "schm";

export const addressSchema = schema({
  _id: {
    type: String,
  },
  address: {
    th: {
      type: String
    },
    en: {
      type: String
    },
  },
  province: {
    th: {
      type: String
    },
    en: {
      type: String
    },
  },
  district: {
    th: {
      type: String
    },
    en: {
      type: String
    },
  },
  subdistrict: {
    th: {
      type: String
    },
    en: {
      type: String
    },
  },
  zipcode: {
    type: String
  },
});

