import schema from "schm";

export const companySchema = schema({
  _id: {
    type: String,
  },
  logo: {
    type: String
  },
  profile: {
    type: String
  },
  banners: {
    type: String
  },
  tel: {
    type: String
  },
  website: {
    type: String
  },
  name: {
    type: Object
  },
  email: {
    type: String
  },
  descriptions: {
    type: Object
  },
  address: {
    type: Object
  },
  businessCategory: {
    type: String
  }
});

